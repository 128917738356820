<template>
  <div class="recognition-page-Two">
    <RecognitionPage :backgroundImage="bgImg_ni" :wordObj="wordDetail_ni" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg_ni: require("@/assets/img/12-Vocabulary/lesson-05-jiu.svg"),
      wordDetail_ni: {
        pinName: "jiǔ",
        chineseName: "九",
        englishName: "nine",
        songUrl: require("@/assets/audio/L1/5-Words/9.mp3"),

      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-Two {
  width: 100%;
  height: 100%;
}
</style>